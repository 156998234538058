import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "src/components/inputType";

import { PATIENT_SITE_TYPE } from "src/constants/cases";
//import useFetch from "src/hooks/useFetch";

import { SiteOfCareCompProps } from "./types";
// import { APIEndpoints } from "src/types/apiTypes";
// import { SiteOfCareResponseType } from "./types";
import { US_STATES } from "src/constants";



const SiteOfCareComp: FC<SiteOfCareCompProps> = ({ control, watch, setValue, getValues ,socListing}): JSX.Element => {
  const [prevSoC, updatePrevSoC] = useState<number>(getValues("shipments_attributes.0.practice_id") || 0);

  const siteOfCareId = watch("shipments_attributes.0.practice_id");


  
    //console.log("Site of Care.....",socListing,"    ",siteOfCareId ,"   ",)


  useEffect(() => {
    updatePrevSoC(siteOfCareId || 0);
    if (siteOfCareId !== 0) {

      const selectedSiteOfCare = socListing && socListing.data.find((data) => data.id === siteOfCareId);
    //console.log("selectedSiteOfCare.....",selectedSiteOfCare,"    ",siteOfCareId ,"   ",)

      if (selectedSiteOfCare) {
        setValue("shipments_attributes.0.email", selectedSiteOfCare.email === 'N/A' ? '' : selectedSiteOfCare.email);
        setValue("shipments_attributes.0.phone", selectedSiteOfCare.phone.toString());
        setValue("shipments_attributes.0.fax", selectedSiteOfCare.fax.toString());
        setValue("shipments_attributes.0.practice_name", selectedSiteOfCare.name);
        setValue("shipments_attributes.0.practice_type", selectedSiteOfCare.address.legacy_ref ? selectedSiteOfCare.address.legacy_ref.toLocaleLowerCase() : '');
        setValue("shipments_attributes.0.ship_to_suite", selectedSiteOfCare.address.suite);
        setValue("shipments_attributes.0.ship_to_street", selectedSiteOfCare.address.street);
        setValue("shipments_attributes.0.ship_to_city", selectedSiteOfCare.address.city);
        setValue("shipments_attributes.0.ship_to_state", selectedSiteOfCare.address.state);
        setValue("shipments_attributes.0.ship_to_zip", selectedSiteOfCare.address.zip);
        setValue("shipments_attributes.0.ship_to", selectedSiteOfCare.name);
      }
    } else if (siteOfCareId !== prevSoC) {
      setValue("shipments_attributes.0", {
        email: "",
        phone: "",
        fax: "",
        ship_to_city: "",
        ship_to_state: "",
        ship_to_street: "",
        ship_to_zip: "",
        ship_to: "other",
        practice_name: "",
        ship_to_suite: "",
        practice_type: "",
        practice_id: 0,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteOfCareId]);

  return (
    <>
      <div className="row">
      
        {/* <div className="col-lg-6">
          <Controller
            name="shipments_attributes.0.practice_id"
            control={control}
            // defaultValue={0}
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                inputId="shipments_attributes.0.practice_id"
                inputLabel="Site of Care"
                field={field}
                error={error}
                isDropdown={true}
                isSearchable={false}
                dropdownData={
                  (siteOfCareApiResponse && siteOfCareApiResponse.data && siteOfCareApiResponse.data.length > 0 ?
                    [{ value: 0, label: 'New...' }, ...siteOfCareApiResponse.data.map((siteOfCare) => ({ value: siteOfCare.id, label: siteOfCare.name }))]
                    : [{ value: 0, label: 'New...' }]) as unknown as OptionsOrGroups<string, GroupBase<string>>
                }
              />
            )}
          />
        </div> */}
      </div>

      {
      siteOfCareId === 0 &&
      <div className="card mt-3">
        <div className="card-header">
          <h4 className="mb-0"> Site of Care </h4>
        </div>

        <div className="card-body">
        <div className="row">
            <div className="col-lg-6">
              <Controller
                name="shipments_attributes.0.ship_to_npi"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    inputId="shipments_attributes.0.ship_to_npi"
                    // inputLabel="NPI"
                    isRequired={false}
                    isRowBased={true}
                    inputPlaceholder="NPI should be 10 digits"
                    field={field}
                    error={error}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Controller
                name="shipments_attributes.0.practice_name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    isRowBased={true}
                    isRequired={true}
                    inputId="shipments_attributes.0.practice_name"
                    inputPlaceholder="Site of Care Name *"
                    field={field}
                    error={error}
                  />
                )}
              />
            </div>

            <div className="col-lg-6">
              <Controller
                name="shipments_attributes.0.practice_type"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    isRequired={true}
                    isRowBased={true}
                    inputId="shipments_attributes.0.practice_type"
                    inputPlaceholder="Select Site Type..."
                    field={field}
                    error={error}
                    isDropdown={true}
                    isSearchable={false}
                    dropdownData={PATIENT_SITE_TYPE as unknown as OptionsOrGroups<string, GroupBase<string>>}
                  />
                )}
              />
            </div>
          </div>

          <div className="row">

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <Controller
                    name="shipments_attributes.0.ship_to_street"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        isRowBased={true}
                        isRequired={true}
                        inputId="shipments_attributes.0.ship_to_street"
                        inputPlaceholder="Street *"
                        field={field}
                        error={error}
                      />
                    )}
                  />
                </div>

                <div className="col-lg-6">
                  <Controller
                    name="shipments_attributes.0.ship_to_suite"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        isRowBased={true}
                        isRequired={false}
                        inputId="shipments_attributes.0.ship_to_suite"
                        inputPlaceholder="Suite"
                        field={field}
                        error={error}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <Controller
                    name="shipments_attributes.0.ship_to_city"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        isRowBased={true}
                        isRequired={true}
                        inputId="shipments_attributes.0.ship_to_city"
                        inputPlaceholder="City *"
                        field={field}
                        error={error}
                      />
                    )}
                  />
                </div>

                <div className="col-lg-3">
                  <Controller
                    name="shipments_attributes.0.ship_to_state"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                      isRowBased={true}
                      isRequired={true}
                      inputId="shipments_attributes.0.ship_to_state"
                      inputPlaceholder="St *"
                      field={field}
                      error={error}
                      isDropdown={true}
                      isSearchable={true}
                      dropdownData={
                        US_STATES.map((state) => ({ label: state.value, value: state.value }))  as unknown as OptionsOrGroups<string, GroupBase<string>>
                      }
                    />
                    )}
                  />
                </div>

                <div className="col-lg-3">
                  <Controller
                    name="shipments_attributes.0.ship_to_zip"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        isRowBased={true}
                        isRequired={true}
                        inputId="shipments_attributes.0.ship_to_zip"
                        inputPlaceholder="Zip *"
                        field={field}
                        error={error}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <Controller
                name="shipments_attributes.0.phone"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    isRowBased={true}
                    isRequired={true}
                    inputId="shipments_attributes.0.phone"
                    inputPlaceholder="Site Phone *"
                    field={field}
                    error={error}
                  />
                )}
              />
            </div>

            <div className="col-lg-4">
              <Controller
                name="shipments_attributes.0.fax"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    isRowBased={true}
                    isRequired={true}
                    inputId="shipments_attributes.0.fax"
                    inputPlaceholder="Site Fax *"
                    field={field}
                    error={error}
                  />
                )}
              />
            </div>

            <div className="col-lg-4">
              <Controller
                name="shipments_attributes.0.email"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    isRowBased={true}
                    isRequired={false}
                    inputId="shipments_attributes.0.email"
                    inputPlaceholder="Contact Email"
                    field={field}
                    error={error}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default SiteOfCareComp;
